import React from "react";
import Link from "gatsby-link";
import Helmet from "react-helmet";

const NotFoundPage = () => (
	<div>
		<Helmet
			title="404 Page Note Found | Geoff Davis"
			meta={[
				{ name: "description", content: "This page does not exist" },
				{ name: "robots", content: "noindex,nofollow" }
			]}
		/>
		<h1>PAGE NOT FOUND</h1>
		<p>
			Sorry, you must have gotten lost along the way. This page does not exist;
			if you were given this URL, perhaps{" "}
			<Link to="/about#contact">contact</Link> Geoff to tell him about this.
		</p>
	</div>
);

export default NotFoundPage;
